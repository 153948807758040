import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Mine from './components/Mine';
import Buy from './components/Buy';
import Friends from './components/Friends';
import axios from 'axios';
import './App.css'; // CSS dosyasını içe aktar

const API_URL = 'YOUR_API_URL'; // Cloudflare Worker URL

const App = () => {
  return (
    <Router>
      <div className="app"> {/* CSS class eklendi */}
        <header className="header"> {/* CSS class eklendi */}
          <div className="logo"> {/* CSS class eklendi */}
            <div className="logo-icon">HW</div> {/* CSS class eklendi */}
            <div className="logo-text">HashWave</div> {/* CSS class eklendi */}
          </div>
        </header>
        <Routes>
          <Route path="/mine" element={<Mine />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/" element={<Mine />} />
        </Routes>
        <footer className="menu"> {/* CSS class eklendi */}
          <Link to="/mine" className="menu-button">Mine</Link> {/* CSS class eklendi */}
          <Link to="/buy" className="menu-button">Buy</Link> {/* CSS class eklendi */}
          <Link to="/friends" className="menu-button">Friends</Link> {/* CSS class eklendi */}
        </footer>
      </div>
    </Router>
  );
};

export default App;
