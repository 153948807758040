import React from 'react';
import { Link } from 'react-router-dom';

const Friends = () => {
  const userId = sessionStorage.getItem('id'); // Örnek bir kullanıcı ID'si
  return (
    <div>
      <h2>Home Page</h2>
      <p>User ID: {userId}</p>
      <Link to={`/mine/${userId}`}>Go to Mine Page</Link>
    </div>
  );
};

export default Friends;