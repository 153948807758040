import React, { useState } from 'react';
import axios from 'axios';

function Buy() {
  const [userid, setUserid] = useState('');
  const [ref, setRef] = useState('');
  const [hashrate, setHashrate] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://hashwavekvworker.osmanadak.workers.dev/user', {
        userid,
        ref,
        hashrate,
      });
      alert('User data saved: ' + response.data.message);
    } catch (error) {
      console.error('Error saving user data:', error);
      alert('Failed to save user data');
    }
  };

  return (
    <div className="page">
      <h1>Buy Page</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>User ID: </label>
          <input type="text" value={userid} onChange={(e) => setUserid(e.target.value)} required />
        </div>
        <div>
          <label>Ref: </label>
          <input type="text" value={ref} onChange={(e) => setRef(e.target.value)} required />
        </div>
        <div>
          <label>Hashrate: </label>
          <input type="text" value={hashrate} onChange={(e) => setHashrate(e.target.value)} required />
        </div>
        <button type="submit" className="button red">Save</button>
      </form>
    </div>
  );
}

export default Buy;
