// components/Mine.js
import React from 'react';
import { useLocation } from 'react-router-dom';

const Mine = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id'); // URL'den "id" parametresini al
  sessionStorage.setItem('id',id);

  return (
    <div>
      <h2>Mine Page</h2>
      <p>User ID: {id}</p>
    </div>
  );
};

export default Mine;
